import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const SocialLink = ({ children, href, className, ...props }) => {
  const classes = classnames('SocialLink', className);

  return (
    <a
      href={href}
      className={classes}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      {children}
    </a>
  );
};

SocialLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default SocialLink;
