import React from 'react';

import SocialLink from '../components/social-link';
import GithubIcon from '../assets/github.svg';
import TwitterIcon from '../assets/twitter.svg';
import LinkedinIcon from '../assets/linkedin.svg';

const Footer = () => (
  <div className="flex items-center justify-center py-4 md:justify-end">
    <SocialLink
      href="https://github.com/kasparasg"
      className="text-grey hover:text-grey-dark"
    >
      <GithubIcon className="fill-current w-5 h-5" />
    </SocialLink>

    <SocialLink
      href="https://twitter.com/kasparasg"
      className="ml-2 text-grey hover:text-grey-dark"
    >
      <TwitterIcon className="fill-current w-5 h-5" />
    </SocialLink>

    <SocialLink
      href="https://www.linkedin.com/in/kasparasg"
      className="ml-2 text-grey hover:text-grey-dark"
    >
      <LinkedinIcon className="fill-current w-5 h-5" />
    </SocialLink>
  </div>
);

export default Footer;
