import React from 'react';
import { Link } from 'gatsby';

import LogoSVG from '../assets/logo.svg';

const Logo = props => (
  <Link to="/">
    <LogoSVG {...props} />
  </Link>
);

export default Logo;
