import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import Nav from './nav';
import Footer from './footer';

import '../styles/styles.css';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <Fragment>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content:
                'Kasparas Galdikas. A Full Stack Engineer from Brighton, UK.',
            },
            { name: 'keywords', content: 'full stack engineer' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <div className="flex flex-col min-h-screen p-4">
          <div className="flex flex-col flex-1 container mx-auto">
            <Nav />

            {children}
          </div>

          <div className="container mx-auto">
            <Footer />
          </div>
        </div>
      </Fragment>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
