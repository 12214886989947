import React from 'react';
import classnames from 'classnames';

import Logo from './logo';

const Nav = ({ className, ...props }) => {
  const classes = classnames('Nav', className);

  return (
    <div className={classes}>
      <div className="text-grey">
        <Logo className="fill-current text-grey w-16 h-auto" />
      </div>

      <div className="Nav__items" />
    </div>
  );
};

export default Nav;
